import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import { AvForm } from 'availity-reactstrap-validation';
import strings from "../../core/translate";
import SelectRow from "../../components/Select/SelectRow";
import {Utilites} from "../../core/Utilites";
import ImageUpload from "../../components/CustomUpload/ImageUpload";


export default class ServiceAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            _id: "",
            title: "",
            orderx:"1",
            status:"active",

            price:"0",
            saleprice:"0",
            picture:"",
            duration:"0",
            credit:"0", // total
            description:"",
            rank:"",
            ranklist:[],

            premiumcourses:"",
            premiumcourseslist:[],

        };
        this.pathname = "service";
        this.engine = new RequestEngine();

        this.idFileBase64 = "";
    }

    componentDidMount() {
        const edit = this.props.location.data
        if (edit) {
            this.setState({
                ...edit,
            });
        }
        this.prepareData();
    }

    onChangePic(e) {
        Utilites.getFileBase64(e.target.files[0], (result) => {
            this.idFileBase64 = result;
        });
    }

    async prepareData() {
        let responseuni = await this.engine.getItemlistDirect("rank");
        if (responseuni) {
            const data = responseuni.data.data;
            let ranklist = [];
            ranklist.push({ value: "", label: "none" });
            data.map((p) => {
                ranklist.push({ value: p._id, label: p.title });
            });
            this.setState({
                ranklist,
            });
        }

        responseuni = await this.engine.getItemlistDirect("premiumcourses");
        if (responseuni) {
            const data = responseuni.data.data;
            let premiumcourseslist = [];
            data.map((p) => {
                premiumcourseslist.push({ value: p._id, label: p.title });
            });
            this.setState({
                premiumcourseslist,
            });
        }
    };
    handleValidSubmit = async () => {

        this.setState({
            loading: true
        })

        let data = { ...this.state }
        data.picture=this.idFileBase64


        const response = await this.engine.saveItem(this.pathname, data)

        if (response && response.status === 200) {
            this.props.history.push('/admin/' + this.pathname);
        }
        this.setState({
            loading: false
        })

    };


    render() {
        // taking all the states
        const { loading, isLoading,  title,
            duration,price,description,saleprice,
            category,status,
            credit,premiumcourses,premiumcourseslist} = this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm onValidSubmit={() => this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        <FormRow label={"title"} name="title" data={title} changeInput={this.changeInput.bind(this)} />

                                        <FormRow label={"old price"} type={"number"} name="saleprice" data={saleprice} changeInput={this.changeInput.bind(this)} />

                                        <FormRow label={"price"} type={"number"} name="price" data={price} changeInput={this.changeInput.bind(this)} />


                                        <SelectRow label="premiumcourses"  name="premiumcourses"   data={premiumcourseslist}  defaultValue={premiumcourses} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"duration / month"} name="duration" data={duration} changeInput={this.changeInput.bind(this)} />

                                        <FormRow label={"credit"} name="credit" data={credit} changeInput={this.changeInput.bind(this)} />

                                        <FormRow label={"description"} type={"textarea"} name="description" data={description} changeInput={this.changeInput.bind(this)} />
                                        <SelectRow
                                            label={"category"}
                                            name="category"
                                            defaultValue={category}
                                            data={this.createSelectValue(["academy","ai","affiliate","channel"])}
                                            changeInput={this.changeInput.bind(this)}
                                        />

                                        <SelectRow
                                            label={"status"}
                                            name="status"
                                            defaultValue={status}
                                            data={this.createSelectValue(["active","notactive"])}
                                            changeInput={this.changeInput.bind(this)}
                                        />





                                        <Row>
                                            <Label sm="2">{strings.picture}  </Label>
                                            <Col sm="5">
                                                <FormGroup >
                                                    <ImageUpload  placeholder={this.state.fullpicture} labelupload="Select Image" onChange={(e) => this.onChangePic(e)}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>





                                    </CardBody>


                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>{strings.save}</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


