import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import { AvForm } from 'availity-reactstrap-validation';
import strings from "../../core/translate";
import SelectRow from "../../components/Select/SelectRow";
import {Utilites} from "../../core/Utilites";
import ImageUpload from "../../components/CustomUpload/ImageUpload";


export default class AddUpdateSignals extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            _id: "",
            title: "",
            action:"buy",
            takeprofit1:"0",
            takeprofit2:"0",
            takeprofit3:"0",
            entryprice:"0",
            stoploss:"0",
            type:"signal",
            description:"",
            showweb:"no",
            priority:"none",
            channelname:"multyncome forex signals",
            result:""

        };
        this.pathname = "signal";
        this.engine = new RequestEngine();

        this.idFileBase64 = "";
    }

    componentDidMount() {
        const edit = this.props.location.data
        if (edit) {
            this.setState({
                ...edit,
            });
        }
    }

    onChangePic(e) {
        Utilites.getFileBase64(e.target.files[0], (result) => {
            this.idFileBase64 = result;
        });
    }

    handleValidSubmit = async () => {

        this.setState({
            loading: true
        })

        let data = { ...this.state }
        data.picture=this.idFileBase64

        const response = await this.engine.saveItem(this.pathname, data)

        if (response && response.status === 200) {
            this.props.history.push('/admin/' + this.pathname);
        }
        this.setState({
            loading: false
        })

    };


    render() {
        // taking all the states
        const { loading, isLoading,  title,
            action,
            entryprice,
            stoploss,
            takeprofit1,
            takeprofit2,
            takeprofit3,
            showweb,
            channelname,
            result,
            priority,
            type,
            description} = this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm onValidSubmit={() => this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <SelectRow
                                            label={"channelname"}
                                            name="channelname"
                                            defaultValue={channelname}
                                            data={this.createSelectValue(["multyncome forex news","multyncome forex signals"])}
                                            changeInput={this.changeInput.bind(this)}
                                        />
                                        <SelectRow
                                            label={"show on web"}
                                            name="showweb"
                                            defaultValue={showweb}
                                            data={this.createSelectValue(["yes","no"])}
                                            changeInput={this.changeInput.bind(this)}
                                        />



                                        <SelectRow
                                            label={"type"}
                                            name="type"
                                            defaultValue={type}
                                            data={this.createSelectValue(["signal","news"])}
                                            changeInput={this.changeInput.bind(this)}
                                        />


                                        <FormRow label={"title"} name="title" data={title} changeInput={this.changeInput.bind(this)} />


                                        {type=="signal" ? <>
                                            <SelectRow
                                                label={"priority"}
                                                name="priority"
                                                defaultValue={priority}
                                                data={this.createSelectValue(["none","level 1 🛑","level 2 🟡","level 3 🟢"])}
                                                changeInput={this.changeInput.bind(this)}
                                            />
                                            <FormRow label={"entryprice"} name="entryprice" data={entryprice} changeInput={this.changeInput.bind(this)} />
                                            <FormRow label={"stoploss"} name="stoploss" data={stoploss} changeInput={this.changeInput.bind(this)} />
                                            <SelectRow
                                                label={"action"}
                                                name="action"
                                                defaultValue={action}
                                                data={this.createSelectValue(["buy","sell"])}
                                                changeInput={this.changeInput.bind(this)}
                                            />
                                            <FormRow label={"takeprofit1"} name="takeprofit1" data={takeprofit1} changeInput={this.changeInput.bind(this)} />
                                            <FormRow label={"takeprofit2"} name="takeprofit2" data={takeprofit2} changeInput={this.changeInput.bind(this)} />
                                            <FormRow label={"takeprofit3"} name="takeprofit3" data={takeprofit3} changeInput={this.changeInput.bind(this)} />


                                            <FormRow required={false} label={"result"} name="result" data={result} changeInput={this.changeInput.bind(this)} />

                                        </>: <FormRow label={"description"} name="description" type={"textarea"} data={description} changeInput={this.changeInput.bind(this)} />
                                        }








                                    </CardBody>


                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>{strings.save}</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


