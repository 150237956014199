import React, { Component } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input,
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine";
import { connect } from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import strings from "../../core/translate";

class MaskedList extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
            globalFilter: "",
            startDateFilter: null,
            endDateFilter: null,
            lessonSummary: {}, // Added to store lesson summary data
        };
        this.pathname = "masked";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage();
    }

    async handleDelete(valueid) {
        if (window.confirm("Are you sure you want to delete?")) {
            this.props.loadingAction(true);
            const result = await this.engine.deleteItem(this.pathname, valueid);
            if (result) {
                this.handlePage();
            }
            this.props.loadingAction(false);
        }
    }

    handleEdit(index, dataid) {
        const data = this.state.dataSource[index].data;

        this.props.history.push({
            pathname: "/admin/" + this.pathname + "/edit/" + dataid,
            data,
        });
    }

    handleActions(index, item) {
        return (
            <UncontrolledDropdown>
                <DropdownToggle
                    aria-expanded={false}
                    aria-haspopup={true}
                    caret
                    className="btn-round btn-block"
                    color="primary"
                    data-toggle="dropdown"
                    id="dropdownMenuButton"
                    type="button"
                >
                    {strings.actions}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem
                        eventKey="2"
                        onClick={() => this.handleEdit(index, item._id)}
                    >
                        {strings.edit}
                    </DropdownItem>
                    <DropdownItem
                        variant="danger"
                        eventKey="1"
                        onClick={() => this.handleDelete(item._id)}
                    >
                        {strings.delete}
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }

    async callPage() {
        const response = await this.engine.getItem(this.pathname);

        if (response && response.status === 200) {
            const courseData = response.data.data;
            const lessonSummary = {};



            this.setState({
                isLoading: false,
                dataSource: courseData.map((item, key) => ({
                    id: key,
                    data: item,
                    title: item.title,
                    status: item.status,
                    link:item.link,
                    created: this.renderDate(item.createdAt),
                    actions: this.handleActions(key, item),
                })),
                lessonSummary: lessonSummary,
            });
        }
        this.props.loadingAction(false);
    }

    handlePage() {
        this.props.loadingAction(true);
        this.callPage();
    }

    handleAdd() {
        this.props.history.push("/admin/" + this.pathname + "/add");
    }

    handleGlobalFilterChange = (event) => {
        const { name, value } = event.target;
        if (name === "globalFilter") {
            this.setState({ globalFilter: value });
        } else if (name === "startDateFilter" || name === "endDateFilter") {
            this.setState({ [name]: value });
        }
    };

    render() {
        const {
            dataSource,
            isLoading,
            globalFilter,
            lessonSummary,
        } = this.state;

        const summaryRows = Object.entries(lessonSummary).map(
            ([lesson, count]) => (
                <div key={lesson}>
                    {` ${count} courses contain the lesson ${lesson}`}
                </div>
            )
        );


        let filteredData = [...dataSource];

        if (globalFilter) {
            filteredData = filteredData.filter((row) =>
                Object.values(row.data).some(
                    (value) =>
                        value &&
                        String(value)
                            .toLowerCase()
                            .includes(globalFilter.toLowerCase())
                )
            );
        }



        let columns = [
            {
                Header: "title",
                accessor: "title",
            },
            {
                Header: "link",
                accessor: "link",
            },

            {
                Header: strings.created,
                accessor: "created",
            },
            {
                Header: strings.actions,
                accessor: "actions",
                sortable: false,
                filterable: false,
            },
        ];

        return (
            <div className="content">
                <Row>
                    <Col md="12">

                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Day</CardTitle>
                                <div>{summaryRows}</div>
                                <Button
                                    color="primary"
                                    className="float-right"
                                    onClick={() => this.handleAdd()}
                                >
                                    {strings.add}
                                </Button>
                            </CardHeader>
                            <CardBody>
                                <ReactTable
                                    data={dataSource}
                                    defaultFilterMethod={this.filterCaseInsensitive}
                                    filterable
                                    loading={isLoading}
                                    columns={columns}
                                    defaultPageSize={10}
                                    showPaginationBottom={true}
                                    className="-striped -highlight primary-pagination"
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    loadingAction: (payload) => dispatch(loadingAction(payload)),
});

export default connect(null, mapDispatchToProps)(MaskedList);

