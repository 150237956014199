import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';
import strings from "../../core/translate";
import Constants from "../../core/Constants";
import Checkboxrow from "../../components/Checkbox/Checkboxrow";
import moment from "moment";
import {Utilites} from "../../core/Utilites";



export default class UserAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            _id:"",
            email:"",
            isnewuser:"no",
            membershipregistredsince:"",
            first_name:"",
            createdAt:"",
            feesjanuary:"0",
            phone_number:"",
            startdate:"",
            enddate:"",
            service:"",
            servicelist:[],
            last_name:"",
            secretcode:"",
            username:"",
            balance:"0",
            credit:"0",
            creditnovember:"0",
            coins:"",
            myreferral:"",
            referral:"",
            country:"",
            rank:"",
            ranklist:[],
            nationalId:"",
            today:new Date(),
            ismember:false,
            mfa:false,
            membershipexpiry:"",
            status:"active",
            history:[],
            loading : false,
            logs:[],
            transition:[],

            userservices:[]
        };
        this.pathname = "user";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const edit = this.props.location.data
        if (edit){

            const membershipexpiry = edit.membershipexpiry ?moment(edit.membershipexpiry).format('YYYY-MM-DD'):"";
            const membershipregistredsince = edit.membershipregistredsince ?moment(edit.membershipregistredsince).format('YYYY-MM-DD'):"";
            this.setState({
                ...edit,
                membershipexpiry,
                isnewuser:edit?.isnewuser?"yes":"no"
               // membershipregistredsince
            });
        }
        this.prepareData();
    }
    async prepareData() {
        let responseuni = await this.engine.getItemlistDirect("rank");
        if (responseuni) {
            const data = responseuni.data.data;
            let ranklist = [];
            ranklist.push({ value: "", label: "none" });
            data.map((p) => {
                ranklist.push({ value: p._id, label: p.title });
            });
            this.setState({
                isLoading:false,
                ranklist,
            });
        }

        const edit = this.props.location.data

        responseuni = await this.engine.getItemlistDirect("logs/",edit?._id);
        if (responseuni) {
            const data = responseuni.data.data;
            this.setState({
                isLoading:false,
                logs:data,
            });
        }


        responseuni = await this.engine.getItemlistDirect("transactionx/",edit?._id);
        if (responseuni) {
            const data = responseuni.data.data;
            this.setState({
                isLoading:false,
                transition:data,
            });
        }



        responseuni = await this.engine.getItemlistDirect("userservices/",edit?._id);
        if (responseuni) {
            const data = responseuni.data.data;
            this.setState({
                isLoading:false,
                userservices:data,
            });
        }

        responseuni = await this.engine.getItemlistDirect("service");
        if (responseuni) {
            const data = responseuni.data.data;
            let servicelist = [];
            data.map((p) => {
                servicelist.push({ value: p._id, label: p.title });
            });
            this.setState({
                servicelist,
            });
        }



    };



    handleValidSubmitServices = async () => {
        this.setState({
            loading: true
        })

        const { _id, service, startdate,enddate} = this.state;



        const data = {
            user:_id,
            service,
            startdate,enddate,

        };

        const response = await this.engine.saveItem("userservice", data)

        if (response && response.status === 200) {
            this.props.history.push('/admin/' + this.pathname);
        }
        this.setState({
            loading: false
        })
    };

    handleValidSubmit = async () => {

        this.setState({
            loading: true
        })

        try {
            let data  = {...this.state}

            const response = await this.engine.saveItem(this.pathname,data)

            if(response && response.status === 200){
                this.props.history.push('/admin/'+this.pathname);
            }
        }catch (e) {
            Utilites.showErrorMessage("Please check the data you are saving!")
        }
        this.setState({
            loading: false
        })

    };


    handleInputChange = (index, field, value) => {
        const updatedServices = [...this.state.userservices];
        updatedServices[index][field] = value;
        this.setState({ userservices: updatedServices });
    };

    render() {
        // taking all the states
        const {loading,isLoading, balance,email,myreferral,
            referral,
            country,
            isnewuser,
            nationalId,
            username,
            ismember,
            phone,
            userservices,
            first_name,
            last_name,
            membershipregistredsince,
            membershipexpiry,
            mfa,
            creditnovember,
            phone_number,
            transition,
            secretcode,
            ranklist,
            credit,
            coins,
            createdAt,
            service,
            startdate,
            servicelist,
            enddate,
            logs,
            rank,
            today,
            status }= this.state;

        if (isLoading) {
            return this.renderProgress()
        }




        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{strings.information}</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                       <FormRow label={"balance"}  name="balance" data={balance.toString()} changeInput={this.changeInput.bind(this)}  />


                                        <FormRow label={"first_name"}  name="first_name" data={first_name} changeInput={this.changeInput.bind(this)}  />
                                        <FormRow label={"last_name"}  name="last_name" data={last_name} changeInput={this.changeInput.bind(this)}  />

                                        <FormRow label={"username"}  name="username" data={username} changeInput={this.changeInput.bind(this)}  />
                                        <FormRow label={"email"} iss name="email" data={email} changeInput={this.changeInput.bind(this)}  />
                                        <FormRow label={"myreferral"}  name="myreferral" data={myreferral} changeInput={this.changeInput.bind(this)}  />
                                        <FormRow label={"referral"} required={false}  name="referral" data={referral} changeInput={this.changeInput.bind(this)}  />
                                        <FormRow label={"phone_number"} required={false}  name="phone_number" data={phone_number.toString()} changeInput={this.changeInput.bind(this)}  />
                                        <SelectRow
                                            label={"isnewuser"}
                                            name="isnewuser"
                                            defaultValue={isnewuser}
                                            data={this.createSelectValue(["yes","no"])}
                                            changeInput={this.changeInput.bind(this)}
                                        />
                                        <FormRow label={"credit"}  name="credit" data={credit.toString()} changeInput={this.changeInput.bind(this)}  />

                                        <FormRow label={"secret code"} required={false}  readonly={true}  name="secretcode" data={secretcode.toString()} changeInput={this.changeInput.bind(this)}  />


                                        <FormRow label={"creditnovember"}  name="creditnovember" data={creditnovember.toString()} changeInput={this.changeInput.bind(this)}  />


                                        <FormRow label={"coins"}  name="coins" data={coins.toString()} changeInput={this.changeInput.bind(this)}  />


                                        <SelectRow
                                            label={"rank"}
                                            name="rank"
                                            defaultValue={rank}
                                            data={ranklist}
                                            changeInput={this.changeInput.bind(this)}
                                        />
                                        <SelectRow
                                            label={"country"}
                                            name="country"
                                            defaultValue={country}
                                            data={this.createSelectValue(Constants.countries,false,true)}
                                            changeInput={this.changeInput.bind(this)}
                                        />
                                        <FormRow label={"nationalId"}  name="nationalId" data={nationalId} changeInput={this.changeInput.bind(this)}  />
                                        <SelectRow
                                            label={"status"}
                                            name="status"
                                            defaultValue={status}
                                            data={this.createSelectValue(["active","not active","banned"])}
                                            changeInput={this.changeInput.bind(this)}
                                        />



                                        <Checkboxrow label="mfa" name="mfa" data={mfa}  changeInput={this.changeInput.bind(this)} />

                                        <Checkboxrow label="is member" name="ismember" data={ismember}  changeInput={this.changeInput.bind(this)} />
                                        {ismember.toString()=="true"  ?<FormRow  label={"membership start since "} required={false}  name="membershipregistredsince" data={membershipregistredsince} changeInput={this.changeInput.bind(this)}  />:<div/>}
                                        {ismember.toString()=="true" ?<FormRow type={"date"} label={"membership expiry date"}  name="membershipexpiry" data={membershipexpiry} changeInput={this.changeInput.bind(this)}  />:<div/>}


                                        <FormRow label={"registred at"} readonly={true}  name="createdAt" data={this.renderDate(createdAt)}   />

                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>{strings.save}</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>


                            <CardBody>
                                <AvForm onValidSubmit={() => this.handleValidSubmitServices()} className="form-horizontal" id="TypeValidation">
                                    <Card>
                                        <CardHeader>
                                            <CardTitle tag="h4">Assign Product for this User</CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <SelectRow
                                                label={"service"}
                                                name="service"
                                                defaultValue={service}
                                                data={servicelist}
                                                changeInput={this.changeInput.bind(this)}
                                            />


                                            <FormRow label={"start date"} type={"date"} name="startdate" data={startdate} changeInput={this.changeInput.bind(this)} />
                                            <FormRow label={"end date"} type={"date"} name="enddate" data={enddate} changeInput={this.changeInput.bind(this)} />

                                        </CardBody>

                                        <CardFooter className="text-center">
                                            <ButtonLoader color="primary" loading={loading}>{strings.save}</ButtonLoader>
                                        </CardFooter>
                                    </Card>
                                </AvForm>
                            </CardBody>
                            <CardBody style={{height: 300,
                                overflow: "scroll"}}>
                                <Table responsive >
                                    <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Start Date</th>
                                        <th>Expiry Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {userservices.map((item, index) => {

                                        const duration = Number(item.service?.duration) || 0; // Ensure it's a number
                                        const expiryDate = item.enddate
                                            ? moment(item.enddate).format("YYYY-MM-DD")
                                            : moment(this.addMonthsToDate(today, duration)).format("YYYY-MM-DD");
                                        return (
                                            <tr key={index}>
                                                <td>{item.service?.title}</td>
                                                <td>
                                                    <Input
                                                        type="date"
                                                        value={moment(item.startdate || item.createdAt).format("YYYY-MM-DD")}
                                                        onChange={(e) => this.handleInputChange(index, "startdate", e.target.value)}
                                                    />
                                                </td>

                                                {/* Editable Expiry Date */}
                                                <td>
                                                    <Input
                                                        type="date"
                                                        value={expiryDate}
                                                        onChange={(e) => this.handleInputChange(index, "enddate", e.target.value)}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}


                                    </tbody>
                                </Table>
                            </CardBody>

                            <CardBody style={{height: 300,
                                overflow: "scroll"}}>
                                <Table responsive >
                                    <thead>
                                    <tr>
                                        <th>Log</th>
                                        <th>Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {logs.map((item,index)=>{

                                        return <tr key={index}>
                                            <td>{item.msg}</td>
                                            <td> {this.renderDate(item.createdAt)}</td>
                                        </tr>
                                    })}


                                    </tbody>
                                </Table>
                            </CardBody>


                            <CardBody style={{height: 300,
                                overflow: "scroll"}}>
                                <Table responsive >
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {transition.map((item,index)=>{

                                        return <tr key={index}>
                                            <td>{item.name}</td>
                                            <td> {this.renderDate(item.createdAt)}</td>
                                        </tr>
                                    })}



                                    </tbody>
                                </Table>
                            </CardBody>






                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


