import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';
import strings from "../../core/translate";
import { Utilites } from "../../core/Utilites";
import Constants from "../../core/Constants";


export default class Video48AddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            _id: "",
            vimeo: "",
            title: "",
            loading: false,
        };
        this.pathname = "videos48x";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const edit = this.props.location.data
        if (edit) {
            this.setState({
                ...edit,
            });
        }
        this.prepareData();
    }
    async prepareData() {
        let responseuni = await this.engine.getItemlistDirect("videos48x");
        if (responseuni) {
            const data = responseuni.data.data;
            if(data){
                this.setState({
                    ...data,
                });
            }

        }

    };


    handleValidSubmit = async () => {
        this.setState({
            loading: true
        })

        const { _id, title, vimeo } = this.state;



        const data = {

            title: title,
            vimeo: vimeo,
            _id
        };

        const response = await this.engine.saveItem(this.pathname, data)

        if (response && response.status === 200) {
            alert("Saved successfully!");
        }
        this.setState({
            loading: false
        })
    };

    onChangePic(e) {
        Utilites.getFileBase64(e.target.files[0], (result) => {
            this.idFileBase64 = result;
        });
    }

    render() {
        // taking all the states
        const { loading, isLoading, title, vimeo,levellist,level, lang, status,statuslist, category, categorylist } = this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm onValidSubmit={() => this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{strings.information}</CardTitle>
                                    </CardHeader>
                                    <CardBody>


                                        <FormRow  label={"title"} name="title" data={title} changeInput={this.changeInput.bind(this)} />
                                        <FormRow  label={"vimeo"} name="vimeo" data={vimeo} changeInput={this.changeInput.bind(this)} />


                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>{strings.save}</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


