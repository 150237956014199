import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
} from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import RequestEngine from '../../core/RequestEngine';
import CoreEngine from '../../core/CoreEngine';
import ButtonLoader from '../../components/ButtonLoader/ButtonLoader';
import FormRow from '../../components/Row/FormRow';
import strings from '../../core/translate';
import SelectRow from "../../components/Select/SelectRow";

class CalendarAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            _id: '',
            title: '',
            description: '',
            date:'',
            remindbefore:"",
            loading: false,
        };
        this.pathname = 'calendar';
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const edit = this.props.location.data;
        if (edit) {
            this.setState({
                ...edit,

            });
        }

    }



    handleValidSubmit = async () => {
        this.setState({
            loading: true
        })

        const { _id, title, description,date,remindbefore } = this.state;



        const data = {
            _id: _id,
            title,
            description,
            date,
            remindbefore
        };

        const response = await this.engine.saveItem(this.pathname, data)

        if (response && response.status === 200) {
            this.props.history.push('/admin/' + this.pathname);
        }
        this.setState({
            loading: false
        })
    };


    handleVideoChange = videos => {
        this.setState({ video: videos });
    };

    render() {
       const { loading,isLoading,_id, title, description,date,remindbefore } = this.state;

        if (isLoading) {
            return this.renderProgress();
        }

        return (
            <div className="content english">
                <h4></h4>
                <Row>
                    <Col md="12">
                        <AvForm onValidSubmit={this.handleValidSubmit} className="form-horizontal" id="TypeValidation">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">{strings.information}</CardTitle>
                                </CardHeader>
                                <CardBody>

                                    <FormRow label="Title" name="title" data={title} changeInput={this.changeInput.bind(this)} />
                                    <FormRow label="date" type={"date"} name="date" data={date} changeInput={this.changeInput.bind(this)} />
                                    <FormRow label="reminder the user before (minutes)" type={"number"} name="remindbefore" data={remindbefore} changeInput={this.changeInput.bind(this)} />

                                    <FormRow type="textarea" required={false} label="Description" name="description" data={description} changeInput={this.changeInput.bind(this)} />

                                </CardBody>
                                <CardFooter className="text-center">
                                    <ButtonLoader color="primary" loading={loading}>
                                        {strings.save}
                                    </ButtonLoader>
                                </CardFooter>
                            </Card>
                        </AvForm>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default CalendarAddUpdate;
